import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

// 路由
import router from './routes'
app.use(router)

app.mount('#app')


