const Login = () => import("@/pages/Login")
const Workplatform = () => import("@/pages/Workplatform")
const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },  {
        path: '/workplatform',
        name: 'workplatform',
        component: Workplatform
    }
]

export default routes