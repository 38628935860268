<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import './assets/css/font.css';
@import './assets/css/normal.css';
</style>
<style lans="scss">
@import './assets/css/common.scss';

#app {
  font-family: 'Microsoft YaHei';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input {
  border: none;
  outline: none;
  background: none;
}
body{
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
}
#app{
  background-color: #333;
  position: relative;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#app:after{
  filter: blur(2px);
  width: 100%;
  height: 100%;
  position: absolute;
}
p{
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.btn {
  border: none;
  outline: none;
}
.btn-sm {
    padding: 5px 10px;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
}

.btn-mid {
    padding: 8px 18px;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 2px;
}

.btn-primary{
    background-color: #333;
}
.btn-gray{
    background-color: #666;
}
</style>
